/* Common custom Knockout bindings
*/

ko.bindingHandlers.toggleVisible = {
    init: function (element, valueAccessor) {
        // Initially set the element to be instantly visible/hidden depending on the value
        var value = valueAccessor();
        $(element).toggle(ko.unwrap(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
    },
    update: function (element, valueAccessor) {
        // Whenever the value subsequently changes, slowly fade the element in or out
        var value = valueAccessor();
        //ko.unwrap(value) ? $(element).fadeIn() : $(element).fadeOut();
        $(element).toggle(ko.unwrap(value));
    }
};

ko.bindingHandlers.afterHtmlRender = {
    update: function (element, valueAccessor, allBindings) {
        // check if element has 'html' binding
        if (!allBindings().html) return;
        // get bound callback (don't care about context, it's ready-to-use ref to function)
        var callback = valueAccessor();
        // fire callback with new value of an observable bound via 'html' binding
        callback(allBindings().html);
    }
};

ko.bindingHandlers.afterIfRender = {
    update: function (element, valueAccessor, allBindings) {
        // check if element has 'with' binding
        if (!allBindings().if) return;
        // get bound callback (don't care about context, it's ready-to-use ref to function)
        var callback = valueAccessor();
        // fire callback with new value of an observable bound via 'with' binding
        callback(allBindings().if);
    }
};

ko.bindingHandlers.afterWithRender = {
    update: function (element, valueAccessor, allBindings) {
        // check if element has 'with' binding
        if (!allBindings().with) return;
        // get bound callback (don't care about context, it's ready-to-use ref to function)
        var callback = valueAccessor();
        // fire callback with new value of an observable bound via 'with' binding
        callback(allBindings().with);
    }
};

ko.bindingHandlers.afterForeach = {
    update: function (element, valueAccessor, allBindings) {
        // check if element has 'foreach' binding
        var foreachBinding = allBindings().foreach;
        if (!foreachBinding && allBindings().template) {
            foreachBinding = allBindings().template.foreach;
        }
        if (!foreachBinding) return;
        // get bound callback (don't care about context, it's ready-to-use ref to function)
        var callback = valueAccessor();
        // fire callback with new value of an observable bound via 'foreach' binding
        callback(ko.utils.unwrapObservable(foreachBinding));
    }
};

ko.bindingHandlers.allowBindings = {
    init: function(elem, valueAccessor) {
        // Let bindings proceed as normal *only if* my value is false
        var shouldAllowBindings = ko.unwrap(valueAccessor());
        return { controlsDescendantBindings: !shouldAllowBindings };
    }
};

ko.bindingHandlers.hiddenInput = {
    trackChange: function (element) {
        var MO = MutationObserver || window.MutationObserver || window.WebKitMutationObserver;
        if (!MO) return null;
        var observer = new MO(function (mutations, observer) {
            if (mutations[0].attributeName == "value") {
                $(element).trigger("input"); //Parsley.js reacts on 'input' event with hidden element
                //$(element).trigger("change");
            }
        });
        observer.observe(element, {
            attributes: true
        });
        return observer;
    },

    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var tagName = element && element.tagName && element.tagName.toLowerCase(),
            isInputElement = tagName == "input";
        var result;
        var observer;
        /* DEBUG and TEST
        // clean up memory when knockout removes binding
        ko.utils.domNodeDisposal.addDisposeCallback(element, function (disposingElement) {
            $(disposingElement).off("change");
            //var $input = $(element).next('.grouped-select').find('.current');
            //if ($input.length > 0) {
            //    $input.off("click");
            //    $input.autocomplete('destroy');
            //}
        });

        if (isInputElement && element.type == "hidden") {
            //handle the field changing
            ko.utils.registerEventHandler(element, "change", function () {
                console.log('>>> hiddenInput change KO:', arguments);
            });

            $(element).on("change", function () {
                console.log('>>> hiddenInput change JQ:', arguments);
            });
        }
        */

        if (ko.isObservable(valueAccessor())) {
            //result = ko.bindingHandlers.textInput.init(element, valueAccessor, allBindingsAccessor, viewModel, context);
            result = ko.bindingHandlers.textInput.init.apply(this, arguments);
        }

        if (isInputElement && element.type == "hidden") {
            observer = ko.bindingHandlers.hiddenInput.trackChange(element);
        }

        // clean up memory when knockout removes binding
        ko.utils.domNodeDisposal.addDisposeCallback(element, function (disposingElement) {
            //$(disposingElement).off("change");
            //var $input = $(element).next('.grouped-select').find('.current');
            //if ($input.length > 0) {
            //    $input.off("click");
            //    $input.autocomplete('destroy');
            //}
            if (observer) {
                observer.disconnect();
                observer = null;
            }
        });
        return result;
    },

    update: function (element, valueAccessor, allBindingsAccessor, bindingContext) {
        var newValue = ko.unwrap(valueAccessor());
        var tagName = element && element.tagName && element.tagName.toLowerCase(),
            isInputElement = tagName == "input";

        var result;
        if (typeof ko.bindingHandlers.textInput.update == "function") {
            result = ko.bindingHandlers.textInput.update.apply(this, arguments);
        }

        // Changes in value to hidden elements don't automatically fire the .change() event. 
        // So, wherever it is that you're setting that value, you also have to tell jQuery to trigger it.
        //if (isInputElement && element.type == "hidden") {
        //    //ko.utils.triggerEvent(element, "change");
        //    $(element).trigger("change");
        //}

        return result;
    }
};

ko.bindingHandlers.hiddenValue = {
    trackChange: function (element) {
        var MO = MutationObserver || window.MutationObserver || window.WebKitMutationObserver;
        if (!MO) return null;
        var observer;
        if ($(element).is('select')) {
            // Usesless for Knockout + SELECT
            // Knockout makes changes via element.selectedIndex
            /*
            observer = new MO(function (mutations, observer) {
                console.log('>> mutations', mutations);
                var g = $.grep(mutations, function (item) {
                    var $target = $(item.target);
                    if ($target.is('select')) {
                        if (item.attributeName == "value") {
                            console.log('>> select', item);
                            return true;
                        }
                    } else if ($target.is('option')) {
                        if (item.attributeName == "selected") {
                            console.log('>> option', item);
                            return true;
                        }
                    }

                    return false;
                });
                if (g.length > 0) {
                    console.log('>> selectedValue', $(element).val());
                    //$(element).trigger("input");
                    //$(element).trigger("change");
                }
            });
            observer.observe(element, {
                subtree: true,
                attributes: true,
                //attributeFilter: ['selected'],
                attributeOldValue: true,
            });
            */
        } else {
            observer = new MO(function (mutations, observer) {
                if (mutations[0].attributeName == "value") {
                    //$(element).trigger("input");
                    $(element).trigger("change");
                }
            });
            observer.observe(element, {
                attributes: true
            });
        }
        return observer;
    },
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var tagName = element && element.tagName && element.tagName.toLowerCase(),
            isInputElement = tagName == "input";
        var result;
        var observer;
        if (ko.isObservable(valueAccessor())) {
            //result = ko.bindingHandlers.value.init(element, valueAccessor, allBindingsAccessor, viewModel, context);
            result = ko.bindingHandlers.value.init.apply(this, arguments);
        }

        if ($(element).is('select')) {
            // Knockout makes changes via element.selectedIndex
            ko.computed(function () {
                var newValue = ko.unwrap(valueAccessor());
                var elementValue = element.value;
                var originalIndex = element.selectedIndex;
                setTimeout(function () {
                    if (originalIndex !== element.selectedIndex) {
                        //ko.utils.triggerEvent(element, "change");
                        //$(element).trigger("change");
                        $(element).trigger("input"); //Parsley.js reacts on 'input' event with hidden element
                    }
                }, 16);
            }, null, { disposeWhenNodeIsRemoved: element });
        }

        if (isInputElement && element.type == "hidden") {
            observer = ko.bindingHandlers.hiddenValue.trackChange(element);
        }

        // clean up memory when knockout removes binding
        ko.utils.domNodeDisposal.addDisposeCallback(element, function (disposingElement) {
            //$(disposingElement).off("change");
            //var $input = $(element).next('.grouped-select').find('.current');
            //if ($input.length > 0) {
            //    $input.off("click");
            //    $input.autocomplete('destroy');
            //}
            if (observer) {
                observer.disconnect();
                observer = null;
            }
        });
        return result;
    },

    update: function (element, valueAccessor, allBindingsAccessor, bindingContext) {
        var newValue = ko.unwrap(valueAccessor());

        if ($(element).is('select')) {
            var originalIndex = element.selectedIndex;
            var result = ko.bindingHandlers.value.update.apply(this, arguments);
            var options = allBindingsAccessor().groupedSelectOptions || {};
            
            //setTimeout(function () {
            //    if (originalIndex !== element.selectedIndex) {
            //        //ko.utils.triggerEvent(element, "change");
            //        //$(element).trigger("change");
            //        $(element).trigger("input");
            //    }
            //}, 16);
            return result;
        } else {
            var tagName = element && element.tagName && element.tagName.toLowerCase(),
                isInputElement = tagName == "input";

            var result = ko.bindingHandlers.value.update.apply(this, arguments);

            // Changes in value to hidden elements don't automatically fire the .change() event. 
            // So, wherever it is that you're setting that value, you also have to tell jQuery to trigger it.
            //if (isInputElement && element.type == "hidden") {
            //    //setTimeout(function () {
            //    //    ko.utils.triggerEvent(element, "change");
            //    //}, 16);
            //    let $element = $(element);
            //    if (newValue !== $element.val()) {
            //        $element.val(newValue).trigger('change');
            //    }
            //}

            return result;
        }
    }
};
