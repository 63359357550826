/*** Knockout custom functions ***/
ko.forcibleComputed = function (readFunc, context, options) {
    var trigger = ko.observable(),
        target = ko.computed(function () {
            trigger();
            return readFunc.call(context);
        }, null, options);
    target.evaluateImmediate = function () {
        trigger.valueHasMutated();
    };
    return target;
};

/** Computed observables accept a deferEvaluation option that prevents 
 * the initial evaluation from happening until something actually tries to retrieve the computed's value.
 
 Wraped version of code from it:
 <code>
 this.fullName = ko.computed(function() {
       return this.firstName() + " " + this.lastName();
 }, this, { deferEvaluation: true });
 </code>
 to this:
 <code>
 this.fullName = ko.deferredComputed(function() {
       return this.firstName() + " " + this.lastName();
 });
 </code>
 **/
ko.deferredComputed = function(evaluatorOrOptions, target, options) {
   options = options || {};

   if (typeof evaluatorOrOptions == "object") {
       evaluatorOrOptions.deferEvaluation = true;   
   } else {
       options.deferEvaluation = true;
   }

   return ko.computed(evaluatorOrOptions, target, options);
};

/**
 * source: https://stackoverflow.com/questions/13701728/how-to-create-knockout-observable-subscription-that-fires-only-once
 * <code>
 * myObs.subsribeOnce(function(newValue) {
 *      // do something...
 * }));
 * </code>
**/
ko.subscribable.fn.subscribeOnce = function (handler, owner, eventName) {
    var subscription = this.subscribe(function (newValue) {
        subscription.dispose();
        handler(newValue);
    }, owner, eventName);
    return subscription;
};

/**
 * source: http://stackoverflow.com/a/18184016/1074592
 * <code>
 * myObs.subscribeChanged(function(newValue, oldValue, isDeferred) {
 *      // do something...
 * }));
 * </code>
**/
ko.subscribable.fn.subscribeChanged = function (callback, callbackTarget, event, options) {
    var boundCallback = callbackTarget ? callback.bind(callbackTarget) : callback;
    var oldValue;
    var subscriptionBefore = this.subscribe(function (_oldValue) {
        oldValue = _oldValue;
    }, this, 'beforeChange');

    var subscriptionAfter = this.subscribe(function (newValue) {
        boundCallback(newValue, oldValue, false);
    }, callbackTarget, event);

    var subscriptionDeferred = null;
    if (options && options['deferred']) {
        var _this = this;
        this._changed = ko.computed(function () {
            return ko.unwrap(_this);
        }, this).extend({ deferred: true });
        this._changed.subscribe(function (newValue) {
            boundCallback(newValue, oldValue, true);
        }, this);
    }

    function dispose() {
        subscriptionBefore.dispose();
        subscriptionAfter.dispose();
        if (subscriptionDeferred) {
            subscriptionDeferred.dispose();
        }
    }

    //return {
    //    subscriptionBefore: subscriptionBefore,
    //    subscriptionAfter: subscriptionAfter,
    //    dispose: dispose
    //};

    // Use it in Dev-mode
    //var subscription = new ko.subscription(this, callback, dispose);
    //subscription.subscriptionBefore = subscriptionBefore;
    //subscription.subscriptionAfter = subscriptionAfter;
    //return subscription;
    return  new ko.subscription(this, boundCallback, dispose);
};

ko.subscription = function (target, callback, disposeCallback) {
    this._target = target;
    this.callback = callback;
    this.disposeCallback = disposeCallback;
    this.isDisposed = false;
    //ko.exportProperty(this, 'dispose', this.dispose);
};
ko.subscription.prototype.dispose = function () {
    this.isDisposed = true;
    this.disposeCallback();
};

ko.utils.uniqueId = (function () {

    var prefixesCounts = {
        'ko-unique-': 0
    };

    return function (prefix) {
        prefix = prefix || 'ko-unique-';

        if (!prefixesCounts[prefix]) {
            prefixesCounts[prefix] = 0;
        }

        return prefix + prefixesCounts[prefix]++;
    };
})();

// Not used now but in the future it could be
//ko.utils.clone = function (obj) {
//    var target = new obj.constructor();
//    for (var prop in obj) {
//        var propVal = obj[prop];
//        if (ko.isObservable(propVal)) {
//            var val = propVal();
//            if ($.type(val) == 'object') {
//                target[prop] = ko.utils.clone(val);
//                continue;
//            }
//            target[prop] = ko.observable(val);
//        }
//    }
//
//    //// Deep copy Way 2
//    //var target = ko.mapping.fromJS(ko.mapping.toJS(obj));
//
//    //// Deep copy Way 3
//    //var options = {
//    //    create: function (options) {
//    //        // map each of the properties
//    //        return ko.mapping.visitModel(options.data, function (value) {
//    //            // create new instances of observables initialized to the same value
//    //            if (ko.isObservable(value)) { // may want to handle more cases
//    //                return ko.observable(value);
//    //            }
//    //            return value;
//    //        });
//    //    }
//    //};
//    //var target = ko.mapping.fromJS(obj, options);
//    return target;
//};

ko.extenders.numeric = function (target, precision) {
    //create a writable computed observable to intercept writes to our observable
    var result = ko.pureComputed({
        read: target,  //always return the original observables value
        write: function (newValue) {
            var current = target(),
                roundingMultiplier = Math.pow(10, precision),
                newValueAsNum = isNaN(newValue) ? 0 : +newValue,
                valueToWrite = Math.round(newValueAsNum * roundingMultiplier) / roundingMultiplier;

            //only write if it changed
            if (valueToWrite !== current) {
                target(valueToWrite);
            } else {
                //if the rounded value is the same, but a different value was written, force a notification for the current field
                if (newValue !== current) {
                    target.notifySubscribers(valueToWrite);
                }
            }
        }
    }).extend({ notify: 'always' });
    // treat used functions from original observables
    result.valueHasMutated = function () {
        target.valueHasMutated();
    };

    //initialize with current value to make sure it is rounded appropriately
    result(target());

    //return the new computed observable
    return result;
};

ko.extenders.parseInt = function (target, forceNotify) {
    //create a writable computed observable to intercept writes to our observable
    var result = ko.pureComputed({
        read: target,  //always return the original observables value
        write: function (newValue) {
            var current = target(),
                valueToWrite = parseInt(newValue);

            if (valueToWrite !== current/* && (isNaN(valueToWrite) !== isNaN(current))*/) {
                //only write if it changed
                target(valueToWrite);
            } else {
                //if the parsed value is the same, but a different value was written, force a notification for the current field
                if (newValue !== current/* && forceNotify*/) {
                    target.notifySubscribers(valueToWrite);
                }
            }
        }
    }).extend({ notify: 'always' });
    // treat used functions from original observables
    result.valueHasMutated = function () {
        target.valueHasMutated();
    };

    //initialize with current value to make sure it is parsed appropriately
    result(target());

    //return the new computed observable
    return result;
};

ko.extenders.params = function (target, definition) {
    target.params = ko.observable();
    target.execute = function () {
        var args = Array.prototype.slice.call(arguments);
        target.params(args);
        return target;
    }

    function executeFunc(newParams) {
        //console.log('executeFunc');
    }
    target.params.subscribe(executeFunc);

    // Return the original observable
    return target;
};

